export * from './reducers/current-user'
export * from './reducers/patients'
export * from './reducers/rendered-services'

export const ADD_ENTITIES = 'ADD_ENTITIES'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const RESET_ENTITIES = 'RESET_ENTITIES'

export function addEntities (entities) {
  return {
    type: ADD_ENTITIES,
    payload: entities
  }
}

export function removeEntity (id) {
  return {
    type: REMOVE_ENTITY,
    payload: id
  }
}

export function resetEntities (entities) {
  return {
    type: RESET_ENTITIES,
    payload: entities
  }
}
