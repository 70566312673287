const { version } = require('../package.json')

module.exports = {
  API_SERVICE_URL: process.env.NEXT_PUBLIC_API_SERVICE_URL,
  SHARED_PROGRAM_API_URL: process.env.NEXT_PUBLIC_SHARED_PROGRAM_API_URL,
  APP_STAGE: process.env.NEXT_PUBLIC_APP_STAGE,
  APP_VERSION: version,
  BUGSNAG_KEY: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  SPLITIO_AUTH_TOKEN: process.env.NEXT_PUBLIC_SPLITIO_AUTH_TOKEN,
  SPLITIO_KEY: process.env.NEXT_PUBLIC_SPLITIO_KEY
}
