import {
  ACCESS_TOKEN_KEY,
  TEMP_ACCESS_TOKEN_KEY,
  CURRENT_USER_ID
} from './constants'

export function removeKey (key) {
  localStorage.removeItem(key)
}

export function storeKey (key, value) {
  if (typeof value === 'undefined') {
    removeKey(key)
    return
  }

  localStorage.setItem(key, btoa(value))
}

export function fetchKey (key) {
  if (typeof localStorage === 'undefined') return

  const value = localStorage.getItem(key)
  if (!value) return value

  return atob(value)
}

export function removeAuthKeys () {
  removeKey(ACCESS_TOKEN_KEY)
  removeKey(CURRENT_USER_ID)
  removeKey(TEMP_ACCESS_TOKEN_KEY)
}

export function authorizationHeaders () {
  const headers = { 'X-Portal-App': 'account' }
  const token = fetchKey(ACCESS_TOKEN_KEY)

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}
