import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  RESET_ENTITIES,
  addEntities,
  resetEntities
} from '../actions'

const INITIAL_STATE = {
  records: {}
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        records: {
          ...state.records,
          ...action.payload.renderedServices
        }
      }
    case RESET_ENTITIES:
      if (!action.payload.renderedServices) return state

      return {
        ...state,
        records: {
          ...action.payload.renderedServices
        }
      }
    default:
      return state
  }
}

export function searchRenderedServices (params = {}) {
  return function (dispatch, getState, { healthcareApi, schema }) {
    return healthcareApi.searchRenderedServices(params).then(response => {
      const data = normalize(response.renderedServices, [
        schema.renderedServices
      ])

      dispatch(
        resetEntities({
          renderedServices: {
            ...data.entities.renderedServices
          }
        })
      )

      return response
    })
  }
}

export function updateRenderedService (id, params = {}) {
  return function (dispatch, getState, { healthcareApi, schema }) {
    return healthcareApi
      .updateRenderedService(id, params)
      .then(function (response) {
        const data = normalize(response, [schema.renderedServices])
        dispatch(addEntities(data.entities))

        return response
      })
  }
}
