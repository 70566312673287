import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose
} from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { debounce } from 'lodash'
import { withExtraArgument } from 'redux-thunk'
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux'
import reducer from './reducer'
import baseApi from '../api'
import healthcareApi from '../api/healthcare'
import * as schema from '../api/schema'
import { CALLING_SERVICES_FLAG } from '../constants'
import { SPLITIO_AUTH_TOKEN, SPLITIO_KEY } from '../env'

let composeEnhancers = compose

if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsDenylist: []
  })
}

const enhancer = composeEnhancers(
  applyMiddleware(withExtraArgument({ baseApi, healthcareApi, schema })),
  batchedSubscribe(debounce(notify => notify()))
)

const defaultState = {}

const sdkBrowserConfig = {
  core: {
    authorizationKey: SPLITIO_AUTH_TOKEN,
    key: SPLITIO_KEY
  }
}

const store = createStore(reducer, defaultState, enhancer)

function onReadyCallback () {
  store.dispatch(getTreatments({ splitNames: [CALLING_SERVICES_FLAG] }))
}

store.dispatch(
  initSplitSdk({ config: sdkBrowserConfig, onReady: onReadyCallback })
)

export default store
