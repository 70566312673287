import { API_SERVICE_URL } from '../env'
import { removeAuthKeys } from '../functions'
import buildAPIClient from './build-client'

const apiClient = buildAPIClient(API_SERVICE_URL)

const api = {
  loginUser (values) {
    return apiClient.post('/auth/login', values).then(response => {
      return response.data.token
    })
  },

  verifyCurrentUser (token = null) {
    let requestOptions = {}
    if (token) {
      requestOptions = {
        headers: { Authorization: `Bearer ${token}` }
      }
    }

    return apiClient
      .post('/auth/verify', null, requestOptions)
      .then(function (response) {
        return response.data.user
      })
  },

  logoutUser () {
    return apiClient.post('/auth/logout').then(function (response) {
      return response
    })
  },

  updatePassword (params) {
    return apiClient.put('/password', params).then(function (response) {
      return response.data
    })
  },

  resetPassword (email) {
    return apiClient
      .post('/passwords/reset', { email })
      .then(function (response) {
        return response.data
      })
  }
}

export default api
