import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

if (process.env.NEXT_PUBLIC_DD_SERVICE) {
  const applicationId = '2375d702-6491-443a-a4db-d1beffdaf69d'
  const clientToken = 'pubfcf91bead24dee15235e048a2a86c9e5'
  const site = 'datadoghq.com'

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: site,
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    env: process.env.NEXT_PUBLIC_DD_ENV,
    version: process.env.NEXT_PUBLIC_DD_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}
