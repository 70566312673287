import { combineReducers } from 'redux'
import currentUser from './reducers/current-user'
import patients from './reducers/patients'
import renderedServices from './reducers/rendered-services'
import { splitReducer } from '@splitsoftware/splitio-redux'

const rootReducer = combineReducers({
  currentUser,
  patients,
  renderedServices,
  splitio: splitReducer
})

export default rootReducer
