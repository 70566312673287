export const ACCESS_TOKEN_KEY = '_ut'
export const TEMP_ACCESS_TOKEN_KEY = '_tut'
export const CURRENT_USER_ID = '_cid' // njsscan-ignore: node_username

export const ALLOWED_ROLES = Object.freeze(['physician'])

export const COLORS = Object.freeze({
  NAVY: '#649372',
  PRIMARY: '#2A3C56',
  GRAY_300: '#B2B2B2',
  WHITE: 'white'
})

export const PAGINATION_DEFAULT_PER_PAGE = 100
export const PAGINATION_DEFAULT_PAGES_TO_SHOW = 5

// NOTE: This list comes from kiosk app registration
export const BILLING_REPORT_INSURANCE_PROVIDERS = [
  { name: 'Aetna', shortName: 'Aetna' },
  { name: 'Blue Cross Blue Shield (BCBS)', shortName: 'BCBS' },
  { name: 'Capital Health Plan (CHP)', shortName: 'CHP' },
  { name: 'Cigna', shortName: 'Cigna' },
  { name: 'Humana', shortName: 'Humana' },
  { name: 'Humana Medicare (MCR)', shortName: 'MCR' },
  { name: 'Medicare (MDCR)', shortName: 'MDCR' },
  { name: 'Meritain', shortName: 'Meritain' },
  { name: 'United Health Care (UHC)', shortName: 'UHC' },
  { name: 'United Health Care Medicare (UHCMCR)', shortName: 'UHCMCR' },
  { name: 'Wellcare', shortName: 'Wellcare' }
]

export const BILLING_REPORT_SERVICES = {
  99453: 'Device Training',
  99454: 'Device Monitoring',
  99457: 'First 20 Minutes of Interactive Communication',
  99458: 'Subsequent 20 Minutes of Interactive Communication'
}

export const BILLING_REPORT_CLAIM_STATUSES = [
  'unsubmitted',
  'submitted',
  'denied',
  'appealed',
  'paid'
]

export const CALLING_SERVICES_FLAG = 'healthcare_portal_calling_cpt_codes'
