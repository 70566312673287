/* eslint-disable */

import '../lib/datadog'
import ErrorBoundary from '@/components/error-boundary'
import store from '@/lib/redux/store'
import { Provider } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

export default function App({ Component, pageProps }) {
  require('../styles/custom.scss')
  require('../styles/globals.scss')

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </ErrorBoundary>
  )
}
